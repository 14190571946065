<template>
  <!-- 新闻中心视频详情 -->
  <div class="news-detail">
    <div class="video-con">
      <p class="p-title">{{ newsObj.name }}</p>
      <p class="p-issueDate">
        <span>{{ newsObj.issueDate }}</span>
      </p>
      <p class="p-desc">
        <span>{{ newsObj.desc }}</span>
      </p>
      <div class="video-box">
        <video
        autoplay
          muted
          preload
          controls
          type="video/mp4"
          :src="newsObj.newsPcImg"
        ></video>
      </div>
    </div>
    <!-- 右侧商标栏目 -->
    <div class="app-item">
      <img
        src="../image/shipin.png"
        width="100%"
        height="100%"
        style="object-fit: cover"
      />
      <p>扫一扫</p>
      <p>关注该视频号</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-detail {
  padding: 20px 300px;
  .p-title {
    font-size: 22px;
    margin-bottom: 14px;
  }
  .p-issueDate {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.3);
  }
  .p-desc {
    font-size: 15px;
    margin: 10px 0px;
  }
  .video-con {
    .video-box {
      height: 500px;
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
// 应用图标按钮
.app-item {
  position: fixed;
  width: 140px;
  right: 40px;
  top: 100px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  img {
    width: 102px;
    height: 102px;
  }
}
</style>

<script>
import {
  getNewsDetail, //获取新闻管理详细信息
} from "@/api/dataSet/newsManegment.js";
export default {
  components: {},
  data() {
    return {
      newsObj: {},
    };
  },
  created() {
    this.getNewsDetail();
  },
  methods: {
    //获取新闻数据
    async getNewsDetail() {
      let res = await getNewsDetail(this.$route.query.id);
      this.newsObj = res.data;
    },
  },
};
</script>